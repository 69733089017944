import {ActionType} from "../actions/Types";
import VKUser from "../models/VKUser";

let initialState = {
    isLoggedInToVk: false,
    user: VKUser
};

let auth = (state = initialState, action) => {

    switch (action.type) {

        case ActionType.SET_VK_AUTH_DATA:
            return {...state,
                isLoggedInToVk: action.isLoggedInToVk,
                user: action.user
            };

        default:
            return state;
    }
};

export default auth;