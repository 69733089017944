import {ActionType} from "./Types";
import FaceRecognitionManager from "../utils/face_recognition/FaceRecognitionManager";
import {localizedStrings} from "../utils/LocalizedStrings";
import ImageHelper from "../utils/ImageHelper";
import NavigationManager from "../utils/NavigationManager";

export function configureFaceRecognition() {
    return async (dispatch) => {
        try {
            dispatch({type: ActionType.SET_MODELS_LOADING_PROGRESS, inProgress: true});
            await FaceRecognitionManager.configure();
            dispatch({type: ActionType.SET_MODELS_LOADING_PROGRESS, inProgress: false});
        } catch(error) {
            dispatch({type: ActionType.SET_MODELS_LOADING_PROGRESS, inProgress: false});
            throw error;
        }
    }
}

export function setFaceToSearch(faceIndex: number, imageView: HTMLImageElement, imageSrc: String) {
    return async (dispatch, getState) => {
        if (!getState().facesSearch.isDeviceSearchSupported) {
            dispatch({ type: ActionType.SET_FACE_TO_SEARCH_IMAGE_SRC, faceIndex: faceIndex, imageSrc: imageSrc });
            dispatch({ type: ActionType.SET_UPLOADED_IMAGE_URL, uploadedImageUrl: null });
            if (imageSrc.startsWith('http'))
                dispatch({ type: ActionType.SET_UPLOADED_IMAGE_URL, uploadedImageUrl: imageSrc });
            return;
        }

        dispatch({type: ActionType.SET_FACE_TO_SEARCH_PROGRESS, faceIndex: faceIndex, inProgress: true});
        function handleError() {
            dispatch({type: ActionType.SET_FACE_TO_SEARCH_PROGRESS, faceIndex: faceIndex, inProgress: false});
            dispatch({type: ActionType.SET_FACE_TO_SEARCH_DESCRIPTORS, faceIndex: faceIndex, faceDescriptor: null});
            dispatch({type: ActionType.SET_FACE_TO_SEARCH_IMAGE_SRC, faceIndex: faceIndex, imageSrc: null});
        }

        try {
            await FaceRecognitionManager.configure();
            let faceDescriptors;
            if (imageSrc.startsWith('http')) faceDescriptors = await FaceRecognitionManager.getFaceDescriptorsByUrl(imageSrc, null);
            else faceDescriptors = await FaceRecognitionManager.getFaceDescriptors(imageView);
            if (faceDescriptors.length > 0) {
                dispatch({type: ActionType.SET_FACE_TO_SEARCH_IMAGE_SRC, faceIndex: faceIndex, imageSrc: imageSrc});
                dispatch({type: ActionType.SET_FACE_TO_SEARCH_DESCRIPTORS, faceIndex: faceIndex, faceDescriptor: faceDescriptors[0]});
                dispatch({type: ActionType.SET_FACE_TO_SEARCH_PROGRESS, faceIndex: faceIndex, inProgress: false});
                return Promise.resolve()
            } else {
                handleError();
                return Promise.reject(localizedStrings.cantDetectFace);

            }
        } catch(error) {
            handleError();
            return Promise.reject(error)
        }
    }
}

export function deleteFaceToSearchData(faceIndex: number) {
    return (dispatch) => {
        dispatch({type: ActionType.DELETE_FACE_TO_SEARCH_DATA, faceIndex: faceIndex});
    }
}

export function openImageInApp(imageSrc) {
    return async (dispatch, getState) => {
        let uploadedImageUrl = getState().facesSearch.uploadedImageUrl
        if (!uploadedImageUrl) {
            dispatch({ type: ActionType.SET_IMAGE_UPLOADING_PROGRESS, inProgress: true });
            try {
                const image = await fetch(imageSrc)
                const blob = await image.blob()
                const file = new File([blob], 'image.png', {type: "image/png"})
                const resizedFile = await ImageHelper.resize(file, 512)
                const data = new FormData()
                data.append('file', resizedFile)
                const response = await fetch('https://photosherlock.com/api/upload-image', {
                    method: 'POST',
                    body: data
                })
                const json = await response.json()
                let url = json['url']
                uploadedImageUrl = url
                dispatch({ type: ActionType.SET_UPLOADED_IMAGE_URL, uploadedImageUrl: url })
            } finally {
                dispatch({ type: ActionType.SET_IMAGE_UPLOADING_PROGRESS, inProgress: false })
            }
        }
        NavigationManager.openFaceSherlockApp(uploadedImageUrl)
    }
}
