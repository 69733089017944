export let Pages = {
    FACES_SEARCH: 'FacesSearch'
};

export let Categories = {
    ADS: 'Ads'
};

export let Actions = {
    ADSENSE_FAILED_TO_SHOW: 'adsense_failed_to_show'
};

export default class AnalyticsService {

    static initialize() {
        // Disable
    }

    static logPageView(name: string) {
        // Disable
    }

    static logEvent(category, action) {
        // Disable
    }
}