import { getDeviceLanguage } from "./LocalizedStrings";

export let Constants = {
    IMAGE_URL_PARAM: "image_url",
    LINK_PARAM: "link",
    APN_PARAM: "apn",
    ISI: "isi",
    IBI: "ibi",
    FACE_SHERLOCK_URL: "https://facesherlock.com",
    FACE_SHERLOCK_DYNAMIC_LINK: "https://facechecker.page.link",
    FACE_SHERLOCK_PACKAGE_NAME: "com.appsmotor.facechecker",
    FACE_SHERLOCK_APP_STORE_ID: "1603900106",
    FACE_SHERLOCK_BUNDLE_ID: "com.appsmotor.facechecker",
    APPSMOTOR_INSTAGRAM_URL: 'https://www.instagram.com/appsmotor/',
};

export default class NavigationManager {

    static privacyPolicyUrl = 'https://privacy-policy-appsmotor.s3.eu-central-1.amazonaws.com/face-sherlock-privacy-policy.html';

    static openUrlInNewTab(url: string) {
        window.open(url, '_blank');
    }

    static scrollToBottom() {
        window.scroll({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        })
    }

    static openFaceSherlockApp(imageUrl) {
        let link = new URL(Constants.FACE_SHERLOCK_URL)
        link.searchParams.append(Constants.IMAGE_URL_PARAM, imageUrl)
        let dynamicLink = new URL(Constants.FACE_SHERLOCK_DYNAMIC_LINK)
        dynamicLink.searchParams.append(Constants.LINK_PARAM, link.href)
        dynamicLink.searchParams.append(Constants.APN_PARAM, Constants.FACE_SHERLOCK_PACKAGE_NAME)
        dynamicLink.searchParams.append(Constants.ISI, Constants.FACE_SHERLOCK_APP_STORE_ID)
        dynamicLink.searchParams.append(Constants.IBI, Constants.FACE_SHERLOCK_BUNDLE_ID)
        window.open(dynamicLink.href)
    }

    static openImageFile(file) {
        window.open(URL.createObjectURL(file), '_blank');
    }

    static copyToClipboard(text) {
        navigator.clipboard.writeText(text);
    }

    static getSupportUkraineUrl() {
        switch (getDeviceLanguage()) {
            case 'es': return 'https://war.ukraine.ua/es/apoya-a-ucrania/'
            case 'de': return 'https://war.ukraine.ua/de/unterstuetzen-sie-die-ukraine/'
            case 'fr': return 'https://war.ukraine.ua/fr/soutenir-l-ukraine/'
            case 'ar': return 'https://war.ukraine.ua/ar/support-ukraine/'
            case 'zh': return 'https://war.ukraine.ua/zh/support-ukraine/'
            default: return 'https://war.ukraine.ua/support-ukraine/'
        }
    }
}
