import {Component} from "react";

export default class AuthHandler extends Component {

    componentDidMount() {
        window.opener.postMessage({
            authUrl: window.location.href
        }, '*');
        window.close();
    }

    render() {
        return null;
    }
}