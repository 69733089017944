import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import FacesSearch from './FacesSearch'
import AuthHandler from "./AuthHandler";

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2962ff',
        },
        secondary: {
            main: '#000000'
        }
    }
});

const Root = ({ store }) => (
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <Route path="/handle_auth" component={AuthHandler} />
                    <Route component={FacesSearch} />
                </Switch>
            </BrowserRouter>
        </Provider>
    </MuiThemeProvider>
);

Root.propTypes = {
    store: PropTypes.object.isRequired
};

export default Root