import React, {Component} from 'react';

export default class Ad extends Component {

    messageEvent = 'message';

    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render () {
        return (
            <ins className='adsbygoogle'
                 style={{ display: 'block', margin: this.props.margin }}
                 data-ad-client='ca-pub-5591173267528583'
                 data-ad-slot={this.props.slot}
                 data-ad-format='auto'
                 data-full-width-responsive='true' />
        );
    }
}