import {ActionType} from "../actions/Types";
import {cloneDeep} from "lodash";

let initialState = {
    modelsLoadingInProgress: false,
    facesExamples: [
        {
            name: 'Megan Fox',
            image: '/facesExamples/Megan_Fox.jpg'
        },
        {
            name: 'Ryan Gosling',
            image: '/facesExamples/Ryan_Gosling.jpg'
        },
        {
            name: 'Margot Robbie',
            image: '/facesExamples/Margot_Robbie.jpg'
        },
        {
            name: 'Chris Hemsworth',
            image: '/facesExamples/Chris_Hemsworth.jpg'
        },
        {
            name: 'Selena Gomez',
            image: '/facesExamples/Selena_Gomez.jpg'
        },
        {
            name: 'Bradley Cooper',
            image: '/facesExamples/Bradley_Cooper.jpg'
        },
    ],
    isImageUploading: false,
    uploadedImageUrl: null,
    isDeviceSearchSupported: true
};

initialState.facesToSearch = [];
let facesToSearchCount = 1;
for (let i = 0; i < facesToSearchCount; i++) {
    initialState.facesToSearch.push({
        faceDescriptor: null,
        imageSrc: null,
        inProgress: false,
    });
}

let facesSearch = (state = initialState, action) => {
    switch (action.type) {

        case ActionType.SET_MODELS_LOADING_PROGRESS:
            return {
                ...state,
                modelsLoadingInProgress: action.inProgress
            };

        case ActionType.SET_FACE_TO_SEARCH_PROGRESS: {
            let facesToSearch = cloneDeep(state.facesToSearch);
            facesToSearch[action.faceIndex].inProgress = action.inProgress;
            return {
                ...state,
                facesToSearch: facesToSearch
            };
        }

        case ActionType.SET_FACE_TO_SEARCH_IMAGE_SRC: {
            let facesToSearch = cloneDeep(state.facesToSearch);
            facesToSearch[action.faceIndex].imageSrc = action.imageSrc;
            return {
                ...state,
                facesToSearch: facesToSearch
            };
        }

        case ActionType.SET_FACE_TO_SEARCH_DESCRIPTORS: {
            let facesToSearch = cloneDeep(state.facesToSearch);
            facesToSearch[action.faceIndex].faceDescriptor = action.faceDescriptor;
            return {
                ...state,
                facesToSearch: facesToSearch
            };
        }

        case ActionType.DELETE_FACE_TO_SEARCH_DATA: {
            let facesToSearch = cloneDeep(state.facesToSearch);
            let faceToSearch = facesToSearch[action.faceIndex];
            faceToSearch.faceDescriptor = null;
            faceToSearch.imageSrc = null;
            faceToSearch.inProgress = false;
            return {
                ...state,
                facesToSearch: facesToSearch
            };
        }

        case ActionType.SET_IMAGE_UPLOADING_PROGRESS:
            return {
                ...state,
                isImageUploading: action.inProgress
            };

        case ActionType.SET_UPLOADED_IMAGE_URL:
            return {
                ...state,
                uploadedImageUrl: action.uploadedImageUrl
            };

        default: return state;
    }
};

export default facesSearch;