import {Params} from "../utils/VKManager";

export default class VKCountry {

    constructor(id: string,
                title: string) {
        this.id = id;
        this.title = title;
    }

    static fromJSON(json: Object): VKCountry {
        if (!json) return null;
        return new VKCountry(json[Params.ID],
            json[Params.TITLE])
    }
}