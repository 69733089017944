import * as faceapi from "face-api.js";
import {localizedStrings} from "../LocalizedStrings";

export let FaceDescriptorDistance = {
    SIMILAR: 0.6,
    MATCH: 0.55
};

export default class FaceRecognitionManager {

    static configured;

    static async configure() {
        if (this.configured) return;
        let modelUrl = '/models';
        await faceapi.loadTinyFaceDetectorModel(modelUrl);
        await faceapi.loadFaceLandmarkTinyModel(modelUrl);
        await faceapi.loadFaceRecognitionModel(modelUrl);
        this.configured = true;
    }

    static async getFaceDescriptorsByUrl(imageUrl: String,
                                         imageLoaded: (src: String) => void): Promise<[faceapi.FullFaceDescription]> {
        let response = await fetch(imageUrl);
        let blob = await response.blob();
        let img = await faceapi.bufferToImage(blob);
        if (imageLoaded) imageLoaded(img.src);
        return this.getFaceDescriptors(img)
    }

    static async getFaceDescriptorsByFile(file): Promise<[faceapi.FullFaceDescription]> {
        let img = await faceapi.bufferToImage(file);
        return this.getFaceDescriptors(img)
    }

    static getFaceDescriptors(input: faceapi.TNetInput): Promise<[faceapi.FullFaceDescription]> {
        let faceDetectorOptions = new faceapi.TinyFaceDetectorOptions({ inputSize: 448 });
        return faceapi
            .detectAllFaces(input, faceDetectorOptions)
            .withFaceLandmarks(true)
            .withFaceDescriptors();
    }

    static isSimilar(faceDescriptors1: [faceapi.FullFaceDescription],
                     faceDescriptors2: [faceapi.FullFaceDescription],
                     maxDistance: number) {
        for (let faceDescriptor1 of faceDescriptors1) {
            for (let faceDescriptor2 of faceDescriptors2) {
                let distance = faceapi.euclideanDistance(faceDescriptor1.descriptor, faceDescriptor2.descriptor);
                if (distance < maxDistance) return true;
            }
        }

        return false;
    }

    static async displayFaceDetections(input: faceapi.TNetInput,
                                       canvas: HTMLCanvasElement, descriptions: [Object],
                                       color): number {
        let inputDescriptions = await this.getFaceDescriptors(input);
        if (inputDescriptions.length === 0) throw new Error(localizedStrings.cantDetectFace);

        let resizedInputDescriptions = this.resizeCanvasAndResults(input, canvas, inputDescriptions);

        let detectionBoxes = [];
        for (let i = 0; i < descriptions.length; i++) {
            let description = descriptions[i];
            for (let j = 0; j < resizedInputDescriptions.length; j++) {
                let resizedInputDescription = resizedInputDescriptions[j];
                if (this.isSimilar([description],
                    [resizedInputDescription],
                    FaceDescriptorDistance.MATCH)) {
                    detectionBoxes.push(new faceapi.BoxWithText(
                        resizedInputDescription.detection.box,
                        localizedStrings.face + ' ' + (i + 1)
                    ))
                }
            }
        }

        if (detectionBoxes.length === 0) throw new Error(localizedStrings.noMatchesFound);

        let options = color ? {
            textColor: color,
            boxColor: color
        } : null;
        faceapi.drawDetection(canvas,
            detectionBoxes,
            options);

        return detectionBoxes.length;
    }

    static resizeCanvasAndResults(dimensions, canvas, results) {
        const { width, height } = dimensions instanceof HTMLVideoElement
            ? faceapi.getMediaDimensions(dimensions)
            : dimensions;
        canvas.width = width;
        canvas.height = height;

        // resize detections (and landmarks) in case displayed image is smaller than
        // original size
        return faceapi.resizeResults(results, { width, height })
    }
}