import {Constants, Params} from "../utils/VKManager";
import VKCountry from "./VKCountry";
import VKCity from "./VKCity";
import moment from "moment";

export default class VKUser {

    constructor(id: string,
                firstName: string,
                lastName: string,
                avatarUrl: string,
                birthDate: Date,
                country: VKCountry,
                city: VKCity) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.avatarUrl = avatarUrl;
        this.country = country;
        this.city = city;
        this.birthDate = birthDate;
    }

    getAge() {
        if (!this.birthDate) return null;
        return moment().diff(this.birthDate, 'years');
    }

    getUrl(): string {
        return Constants.USER_URL + this.id;
    }

    static fromJSON(json: Object): VKUser {
        if (!json) return null;
        let momentBirthDate = moment(json[Params.BDATE], Constants.BIRTH_DATE_FORMAT, true);
        let birthDate = momentBirthDate.isValid() ? momentBirthDate.toDate() : null;
        return new VKUser(json[Params.ID],
            json[Params.FIRST_NAME],
            json[Params.LAST_NAME],
            json[Params.PHOTO_400_ORIG],
            birthDate,
            VKCountry.fromJSON(json[Params.COUNTRY]),
            VKCity.fromJSON(json[Params.CITY]))
    }
}