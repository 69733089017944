import {ActionType} from "./Types";
import {SearchStatus} from "../reducers/DeviceSearch";
import FaceRecognitionManager, {FaceDescriptorDistance} from "../utils/face_recognition/FaceRecognitionManager";

export function setDataToProcess(data) {
    return async (dispatch) => {
        let images = Array.from(data).filter((file) => file.type.includes('image/'));
        if (images.length === 0) return;
        dispatch({
            type: ActionType.SET_DEVICE_SEARCH_DATA_TO_PROCESS,
            data: images
        });
        let folderName = images[0].webkitRelativePath.split('/')[0];
        dispatch({
            type: ActionType.SET_DEVICE_SEARCH_SELECTED_FOLDER_NAME,
            name: folderName
        });
    }
}

export function startSearch() {
    return async (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_DEVICE_SEARCH_STATUS,
            status: SearchStatus.IN_PROGRESS
        });
        function deviceSearchState() {
            return getState().deviceSearch;
        }
        async function compareFaces() {
            var processingItemIndex = deviceSearchState().processingItemIndex;
            let data = deviceSearchState().dataToProcess[processingItemIndex];
            var imageSrc = '';
            try {
                imageSrc = await readImage(data);
            } catch (error) {
                console.log(error);
            }

            let inProgressItem = {
                inProgress: true,
                data: data,
                imageSrc: imageSrc
            };
            if (deviceSearchState().searchItems.length === 0) {
                dispatch({
                    type: ActionType.ADD_DEVICE_SEARCH_ITEM,
                    item: inProgressItem
                });
            } else {
                dispatch({
                    type: ActionType.SET_DEVICE_SEARCH_ITEM,
                    item: inProgressItem,
                    index: 0
                });
            }

            let faceDescriptors = [];
            try {
                faceDescriptors = await FaceRecognitionManager.getFaceDescriptorsByFile(data);
            } catch (error) {
                console.log(error);
            }
            if (deviceSearchState().searchStatus != SearchStatus.IN_PROGRESS) return;
            let facesDescriptorsToSearch = getState().facesSearch.facesToSearch
                .map((faceToSearch) => faceToSearch.faceDescriptor)
                .filter((faceDescriptor) => faceDescriptor);
            if (FaceRecognitionManager.isSimilar(faceDescriptors,
                facesDescriptorsToSearch,
                FaceDescriptorDistance.MATCH)) {
                dispatch({
                    type: ActionType.ADD_DEVICE_SEARCH_ITEM,
                    item: {
                        ...inProgressItem,
                        inProgress: false
                    }
                });
            }

            processingItemIndex += 1;
            if (processingItemIndex >= deviceSearchState().dataToProcess.length) {
                dispatch({
                    type: ActionType.SET_DEVICE_SEARCH_STATUS,
                    status: SearchStatus.FINISHED
                });
            } else {
                dispatch({
                    type: ActionType.SET_DEVICE_SEARCH_PROCESSING_ITEM_INDEX,
                    index: processingItemIndex
                });
                await compareFaces();
            }
        }
        await compareFaces();
    }
}

function readImage(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            let imageSrc = reader.result;
            resolve(imageSrc);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
}

export function pauseSearch() {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_DEVICE_SEARCH_STATUS,
            status: SearchStatus.PAUSED
        });
    }
}

export function stopSearch() {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_DEVICE_SEARCH_STATUS,
            status: SearchStatus.STOPPED
        });
        dispatch({
            type: ActionType.SET_DEVICE_SEARCH_PROCESSING_ITEM_INDEX,
            index: 0
        });
        dispatch({
            type: ActionType.SET_DEVICE_SEARCH_ITEMS,
            items: []
        });
    }
}