import * as faceapi from "face-api.js";
import type {VKSearchItem} from "../reducers/VKSearch";
import VKUser from "../models/VKUser";
import VKCountry from "../models/VKCountry";
import VKCity from "../models/VKCity";

export let ActionType = {

    // Faces search
    SET_MODELS_LOADING_PROGRESS: 'SET_MODELS_LOADING_PROGRESS',
    SET_FACE_TO_SEARCH_PROGRESS: 'SET_FACE_TO_SEARCH_PROGRESS',
    SET_FACE_TO_SEARCH_IMAGE_SRC: 'SET_FACE_TO_SEARCH_IMAGE_SRC',
    SET_FACE_TO_SEARCH_DESCRIPTORS: 'SET_FACE_TO_SEARCH_DESCRIPTORS',
    DELETE_FACE_TO_SEARCH_DATA: 'DELETE_FACE_TO_SEARCH_DATA',
    SET_IMAGE_UPLOADING_PROGRESS: 'SET_IMAGE_UPLOADING_PROGRESS',
    SET_UPLOADED_IMAGE_URL: 'SET_UPLOADED_IMAGE_URL',

    // Auth
    SET_VK_AUTH_DATA: 'SET_VK_AUTH_DATA',

    // VK Search
    SET_VK_SEARCH_COUNTRIES_IN_PROGRESS: 'SET_VK_SEARCH_COUNTRIES_IN_PROGRESS',
    SET_VK_SEARCH_COUNTRIES: 'SET_VK_SEARCH_COUNTRIES',
    SET_VK_SEARCH_COUNTRY: 'SET_VK_SEARCH_COUNTRY',

    SET_VK_SEARCH_CITIES_IN_PROGRESS: 'SET_VK_SEARCH_CITIES_IN_PROGRESS',
    SET_VK_SEARCH_CITIES: 'SET_VK_SEARCH_CITIES',
    SET_VK_SEARCH_CITY: 'SET_VK_SEARCH_CITY',

    SET_VK_SEARCH_MALES: 'SET_VK_SEARCH_MALES',
    SET_VK_SEARCH_FEMALES: 'SET_VK_SEARCH_FEMALES',

    SET_VK_SEARCH_AGE_FROM: 'SET_VK_SEARCH_AGE_FROM',
    SET_VK_SEARCH_AGE_TO: 'SET_VK_SEARCH_AGE_TO',
    SET_VK_SEARCH_AGE_OPTIONS_TO: 'SET_VK_SEARCH_AGE_OPTIONS_TO',
    SET_VK_SEARCH_ONLY_VERY_SIMILAR_FACES: 'SET_VK_SEARCH_ONLY_VERY_SIMILAR_FACES',

    SET_VK_SCHOOL_IDS_TO_SEARCH: 'SET_VK_SCHOOL_IDS_TO_SEARCH',
    SET_VK_SCHOOL_ID_TO_SEARCH: 'SET_VK_SCHOOL_ID_TO_SEARCH',

    ADD_VK_SEARCH_PROCESSED_USER_ID: 'ADD_VK_SEARCH_PROCESSED_USER_ID',
    CLEAR_VK_SEARCH_PROCESSED_USER_IDS: 'CLEAR_VK_SEARCH_PROCESSED_USER_IDS',

    SET_VK_SEARCH_STATUS: 'SET_VK_SEARCH_STATUS',
    SET_VK_SEARCH_PROCESSING_ITEM_INDEX: 'SET_VK_SEARCH_PROCESSING_ITEM_INDEX',
    SET_VK_SEARCH_DATA_TO_PROCESS: 'SET_VK_SEARCH_DATA_TO_PROCESS',
    ADD_VK_SEARCH_ITEM: 'ADD_VK_SEARCH_ITEM',
    SET_VK_SEARCH_ITEM: 'SET_VK_SEARCH_ITEM',
    SET_VK_SEARCH_ITEMS: 'SET_VK_SEARCH_ITEMS',
    SET_VK_SEARCH_TOTAL_ITEMS_COUNT_TO_PROCESS: 'SET_VK_SEARCH_TOTAL_ITEMS_COUNT_TO_PROCESS',
    SET_VK_SEARCH_PROCESSED_ITEMS_COUNT: 'SET_VK_SEARCH_PROCESSED_ITEMS_COUNT',

    // Device search
    SET_DEVICE_SEARCH_STATUS: 'SET_DEVICE_SEARCH_STATUS',
    SET_DEVICE_SEARCH_PROCESSING_ITEM_INDEX: 'SET_DEVICE_SEARCH_PROCESSING_ITEM_INDEX',
    SET_DEVICE_SEARCH_DATA_TO_PROCESS: 'SET_DEVICE_SEARCH_DATA_TO_PROCESS',
    ADD_DEVICE_SEARCH_ITEM: 'ADD_DEVICE_SEARCH_ITEM',
    SET_DEVICE_SEARCH_ITEM: 'SET_DEVICE_SEARCH_ITEM',
    SET_DEVICE_SEARCH_ITEMS: 'SET_DEVICE_SEARCH_ITEMS',
    SET_DEVICE_SEARCH_SELECTED_FOLDER_NAME: 'SET_DEVICE_SEARCH_SELECTED_FOLDER_NAME',

    // Search on photo
    SET_SEARCH_ON_PHOTO_IMG_SRC: 'SET_SEARCH_ON_PHOTO_IMG_SRC',
    SET_SEARCH_ON_PHOTO_MATCHED_FACES_COUNT: 'SET_SEARCH_ON_PHOTO_MATCHED_FACES_COUNT'
};

export type Action =

    // Faces search
    | {type: ActionType.SET_MODELS_LOADING_PROGRESS, inProgress: boolean}
    | {type: ActionType.SET_FACE_TO_SEARCH_PROGRESS, faceIndex: number, inProgress: boolean}
    | {type: ActionType.SET_FACE_TO_SEARCH_IMAGE_SRC, faceIndex: number, imageSrc: String}
    | {type: ActionType.SET_FACE_TO_SEARCH_IMAGE_SRC, faceIndex: number, faceDescriptors: [faceapi.FullFaceDescription]}
    | {type: ActionType.SET_FACE_TO_SEARCH_DESCRIPTORS, faceIndex: number, faceDescriptor: faceapi.FullFaceDescription}
    | {type: ActionType.DELETE_FACE_TO_SEARCH_DATA, faceIndex: number}

    // Auth
    | {type: ActionType.SET_VK_AUTH_DATA, isLoggedInToVk: boolean, user: VKUser}

    // VK Search
    | {type: ActionType.SET_VK_SEARCH_COUNTRIES_IN_PROGRESS, inProgress: boolean}
    | {type: ActionType.SET_VK_SEARCH_COUNTRIES, items: [Object]}
    | {type: ActionType.SET_VK_SEARCH_COUNTRY, country: VKCountry}

    | {type: ActionType.SET_VK_SEARCH_CITIES_IN_PROGRESS, inProgress: boolean}
    | {type: ActionType.SET_VK_SEARCH_CITIES, items: [Object]}
    | {type: ActionType.SET_VK_SEARCH_CITY, city: VKCity}

    | {type: ActionType.SET_VK_SEARCH_MALES, value: boolean}
    | {type: ActionType.SET_VK_SEARCH_FEMALES, value: boolean}

    | {type: ActionType.SET_VK_SEARCH_AGE_FROM, value: number}
    | {type: ActionType.SET_VK_SEARCH_AGE_TO, value: number}
    | {type: ActionType.SET_VK_SEARCH_AGE_OPTIONS_TO, options: [number]}
    | {type: ActionType.SET_VK_SEARCH_ONLY_VERY_SIMILAR_FACES, value: boolean}

    | {type: ActionType.SET_VK_SCHOOL_IDS_TO_SEARCH, ids: [number]}
    | {type: ActionType.SET_VK_SCHOOL_ID_TO_SEARCH, id: number}

    | {type: ActionType.ADD_VK_SEARCH_PROCESSED_USER_ID, id: number}
    | {type: ActionType.CLEAR_VK_SEARCH_PROCESSED_USER_IDS}

    | {type: ActionType.SET_VK_SEARCH_STATUS, status: string}
    | {type: ActionType.SET_VK_SEARCH_PROCESSING_ITEM_INDEX, index: number}
    | {type: ActionType.SET_VK_SEARCH_DATA_TO_PROCESS, data: []}
    | {type: ActionType.ADD_VK_SEARCH_ITEM, item: VKSearchItem}
    | {type: ActionType.SET_VK_SEARCH_ITEM, item: VKSearchItem, index: number}
    | {type: ActionType.SET_VK_SEARCH_ITEMS, items: [VKSearchItem]}
    | {type: ActionType.SET_VK_SEARCH_TOTAL_ITEMS_COUNT_TO_PROCESS, count: number}
    | {type: ActionType.SET_VK_SEARCH_PROCESSED_ITEMS_COUNT, count: number}

    // Device search
    | {type: ActionType.SET_DEVICE_SEARCH_STATUS, status: string}
    | {type: ActionType.SET_DEVICE_SEARCH_PROCESSING_ITEM_INDEX, index: number}
    | {type: ActionType.SET_DEVICE_SEARCH_DATA_TO_PROCESS, data: []}
    | {type: ActionType.ADD_DEVICE_SEARCH_ITEM, item: Object}
    | {type: ActionType.SET_DEVICE_SEARCH_ITEM, item: Object, index: number}
    | {type: ActionType.SET_DEVICE_SEARCH_ITEMS, items: []}
    | {type: ActionType.SET_DEVICE_SEARCH_TOTAL_ITEMS_COUNT_TO_PROCESS, count: number}
    | {type: ActionType.SET_DEVICE_SEARCH_PROCESSED_ITEMS_COUNT, count: number}
    | {type: ActionType.SET_DEVICE_SEARCH_SELECTED_FOLDER_NAME, name: String}

    // Search on photo
    | {type: ActionType.SET_SEARCH_ON_PHOTO_IMG_SRC, imgSrc: string}
    | {type: ActionType.SET_SEARCH_ON_PHOTO_MATCHED_FACES_COUNT, count: number}
