import {Component} from "react";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import React from "react";
import './BaseSnackbar.css';

export default class BaseSnackBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: null
        };
    }

    showMessage(message: string) {
        this.setState({
            message: message
        })
    }

    showError(error: Error) {
        this.setState({
            message: error.toString()
        })
    }

    handleError = (error) => {
        this.showError(error);
    };

    closeSnackBar = () => {
        this.setState({
            message: null
        })
    };

    render() {
        return (
            <div className={this.state.message ? 'BaseSnackbarRoot' : null}>
                <Snackbar
                    open={this.state.message}
                    onClose={this.closeSnackBar}
                    message={this.state.message}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.closeSnackBar}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </div>
        );
    }
}