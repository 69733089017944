import {combineReducers} from 'redux'
import facesSearch from "./FacesSearch";
import auth from "./Auth";
import vkSearch from "./VKSearch";
import searchOnPhoto from "./SearchOnPhoto";
import deviceSearch from "./DeviceSearch";

export default combineReducers({
    facesSearch,
    auth,
    vkSearch,
    searchOnPhoto,
    deviceSearch
})