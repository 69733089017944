import {ActionType} from "../actions/Types";

let initialState = {
    imgSrc: null,
    matchedFacesCount: 0
};

let searchOnPhoto = (state = initialState, action) => {
    switch(action.type) {

        case ActionType.SET_SEARCH_ON_PHOTO_IMG_SRC:
            return {
                ...state,
                imgSrc: action.imgSrc
            };

        case ActionType.SET_SEARCH_ON_PHOTO_MATCHED_FACES_COUNT:
            return {
                ...state,
                matchedFacesCount: action.count
            };

        default: return state;
    }
};

export default searchOnPhoto;