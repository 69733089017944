import VKUser from "../models/VKUser";
import {localizedStrings} from "./LocalizedStrings";

export let Constants = {
    AUTH_URL: 'https://oauth.vk.com/authorize',
    API_URL: 'https://api.vk.com/method/',
    REDIRECT_URI: 'https://facesherlock.com/handle_auth',
    API_VERSION: 5.92,
    MAX_ITEMS_COUNT: 1000,
    BIRTH_DATE_FORMAT: 'D.M.YYYY',
    USER_URL: 'https://vk.com/id',
    REQUEST_MIN_INTERVAL: 500,
    APPSMOTOR_VK_GROUP_ID: 177861879,
    APPSMOTOR_VK_GROUP_URL: 'https://vk.com/public177861879',
};

export let Params = {
    ACCESS_TOKEN: 'access_token',
    V: 'v',
    RESPONSE: 'response',
    ITEMS: 'items',
    ERROR: 'error',
    ERROR_MSG: 'error_msg',
    FIELDS: 'fields',
    PHOTO_400_ORIG: 'photo_400_orig',
    COUNT: 'count',
    ID: 'id',
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    Q: 'q',
    COUNTRY_ID: 'country_id',
    CITY_IDS: 'city_ids',
    COUNTRY: 'country',
    CITY: 'city',
    CITY_ID: 'city_id',
    TITLE: 'title',
    LANG: 'lang',
    NEED_ALL: 'need_all',
    SEX: 'sex',
    AGE_FROM: 'age_from',
    AGE_TO: 'age_to',
    HAS_PHOTO: 'has_photo',
    BDATE: 'bdate',
    OFFSET: 'offset',
    SCHOOL: 'school',
    GROUP_ID: 'group_id',
    MEMBER: 'member'
};

let Methods = {
    GET_USERS: 'users.get',
    SEARCH_USERS: 'users.search',
    GET_COUNTRIES: 'database.getCountries',
    GET_CITIES: 'database.getCities',
    GET_CITIES_BY_ID: 'database.getCitiesById',
    GET_SCHOOLS: 'database.getSchools',
    IS_GROUP_MEMBER: 'groups.isMember'
};

let HTTPMethods = {
    GET: 'GET'
};

export let VKGender = {
    ANY: 0,
    FEMALE: 1,
    MALE: 2
};

let accessToken = null;
let previousRequestDate = null;
let VK = window.VK;

export default class VKManager {

    static getAuthUrl(): String {
        return Constants.AUTH_URL
            + '?client_id=' + Constants.CLIENT_ID
            + '&display=page'
            + '&redirect_uri=' + Constants.REDIRECT_URI
            + '&response_type=token'
            + '&v=' + Constants.API_VERSION
        + '&scope='
    }

    static authorize() {
        window.open(this.getAuthUrl());
    }

    static signOut() {
        accessToken = null;
    }

    static takeAccessTokenFromUrl(urlString: string) {
        let url = new URL(urlString);
        let query = new URLSearchParams(url.hash.replace('#', ''));
        let accessTokenParam = query.get('access_token');
        if (accessTokenParam == null) throw new Error(localizedStrings.vkManagerAccessTokenNotFound);
        accessToken = accessTokenParam;
    }

    static sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    static async callMethod(methodName: string,
                            params: JSON,
                            method = 'GET',
                            requestMinInterval: number = Constants.REQUEST_MIN_INTERVAL) {
        let timeout = null; // VK has limited requests per second
        if (previousRequestDate)
            timeout = requestMinInterval - (new Date().getTime() - previousRequestDate.getTime());
        if (timeout > 0) await this.sleep(timeout);
        let jsonParams = [];
        if (params)
            Object.keys(params).forEach(key => jsonParams[key] = params[key]);
        jsonParams[Params.ACCESS_TOKEN] = accessToken;
        jsonParams[Params.V] = Constants.API_VERSION;
        jsonParams[Params.LANG] = localizedStrings.getLanguage();

        previousRequestDate = new Date();

        let apiCallPromise = new Promise(function(resolve, reject) {
            VK.Api.call(methodName, jsonParams, function(result) {
                let response = result[Params.RESPONSE];
                let error = result[Params.ERROR];
                if (error) reject(error[Params.ERROR_MSG]);
                else if(response != null) resolve(response);
                else reject(localizedStrings.unknownError);
            });
        });

        return await apiCallPromise;
    }

    static async getCountries() {
        let params = [];
        params[Params.NEED_ALL] = true;
        params[Params.COUNT] = Constants.MAX_ITEMS_COUNT;
        let response = await this.callMethod(Methods.GET_COUNTRIES, params);
        return response[Params.ITEMS] || []
    }

    static async getCities(searchQuery: string, countryId: number) {
        let params = [];
        if (searchQuery) params[Params.Q] = searchQuery;
        if (countryId) params[Params.COUNTRY_ID] = countryId;
        let response = await this.callMethod(Methods.GET_CITIES, params);
        return response[Params.ITEMS] || []
    }

    static async getCity(id: number) {
        let params = [];
        params[Params.CITY_IDS] = id;
        let response = await this.callMethod(Methods.GET_CITIES_BY_ID, params);
        let cities = response[Params.RESPONSE];
        if (cities.length > 0) return cities[0];
        else return null;
    }

    static async getCurrentUser() {
        let params = [];
        params[Params.FIELDS] = Params.COUNTRY + ',' + Params.CITY;
        let users = await this.callMethod(Methods.GET_USERS, params);
        return users.length === 0 ? null : VKUser.fromJSON(users[0]);
    }

    static async searchUsers(countryId: number,
                             cityId: number,
                             gender: number,
                             ageFrom: number,
                             ageTo: number,
                             schoolId: number,
                             count: number,
                             offset: number,
                             requestMinInterval: number = Constants.REQUEST_MIN_INTERVAL) {
        let params = [];
        let fields = [Params.PHOTO_400_ORIG,
            Params.BDATE];
        params[Params.FIELDS] = fields.reduce((previousValue, currentValue) =>
            previousValue + (previousValue.length === 0 ? '' : ',') + currentValue);
        if (countryId) params[Params.COUNTRY] = countryId;
        if (cityId) params[Params.CITY] = cityId;
        if (gender) params[Params.SEX] = gender;
        if (ageFrom) params[Params.AGE_FROM] = ageFrom;
        if (ageTo) params[Params.AGE_TO] = ageTo;
        if (schoolId) params[Params.SCHOOL] = schoolId;
        if (offset) params[Params.OFFSET] = offset;
        params[Params.COUNT] = count;
        params[Params.HAS_PHOTO] = true;
        return await this.callMethod(Methods.SEARCH_USERS, params, HTTPMethods.GET, requestMinInterval);
    }

    static async getSchools(cityId: number, count: number, requestMinInterval: number = Constants.REQUEST_MIN_INTERVAL) {
        let params = [];
        params[Params.CITY_ID] = cityId;
        if (count) params[Params.COUNT] = count;
        let response = await this.callMethod(Methods.GET_SCHOOLS, params, HTTPMethods.GET, requestMinInterval);
        if (response) return response[Params.ITEMS];
        else return null;
    }

    static async isGroupMember(groupId: number) {
        let params = [];
        params[Params.GROUP_ID] = groupId;
        let response = await this.callMethod(Methods.IS_GROUP_MEMBER, params);
        if (response) return response[Params.MEMBER];
        else return null;
    }
}