import {ActionType} from "../actions/Types";

export type VKSearchItem = {
    inProgress: boolean,
    data: Object
}

export let SearchStatus = {
    STOPPED: 'STOPPED',
    IN_PROGRESS: 'IN_PROGRESS',
    PAUSED: 'PAUSED',
    FINISHED: 'FINISHED'
};

let initialState = {
    countriesRequestInProgress: false,
    country: null,
    countries: [],
    citiesRequestInProgress: false,
    city: null,
    cities: [],
    searchMales: true,
    searchFemales: true,
    ageOptionsFrom: createAgeOptions(),
    ageOptionsTo: createAgeOptions(),
    ageFrom: null,
    ageTo: null,
    searchOnlyVerySimilarFaces: false,

    schoolIdsToSearch: [],
    schoolIdToSearch: null,

    processedUserIds: new Set(),

    searchStatus: SearchStatus.STOPPED,
    dataToProcess: [],
    processingItemIndex: 0,
    searchItems: [],
    totalItemsCountToProcess: 0,
    processedItemsCount: 0
};

export function createAgeOptions(from: number = 0): [number] {
    let maxAge = 150;
    let ageOptions = [];
    for (let i = from; i <= maxAge; i++) {
        ageOptions.push(i);
    }
    return ageOptions;
}

let vkSearch = (state = initialState, action) => {
    switch (action.type) {

        case ActionType.SET_VK_SEARCH_COUNTRIES_IN_PROGRESS:
            return {
                ...state,
                countriesRequestInProgress: action.inProgress
            };

        case ActionType.SET_VK_SEARCH_COUNTRIES:
            return {
                ...state,
                countries: action.items
            };

        case ActionType.SET_VK_SEARCH_COUNTRY:
            return {
                ...state,
                country: action.country
            };

        case ActionType.SET_VK_SEARCH_CITIES_IN_PROGRESS:
            return {
                ...state,
                citiesRequestInProgress: action.inProgress
            };

        case ActionType.SET_VK_SEARCH_CITIES:
            return {
                ...state,
                cities: action.items
            };

        case ActionType.SET_VK_SEARCH_CITY:
            return {
                ...state,
                city: action.city
            };

        case ActionType.SET_VK_SEARCH_MALES:
            return {
                ...state,
                searchMales: action.value
            };

        case ActionType.SET_VK_SEARCH_FEMALES:
            return {
                ...state,
                searchFemales: action.value
            };

        case ActionType.SET_VK_SEARCH_AGE_FROM:
            return {
                ...state,
                ageFrom: action.value
            };

        case ActionType.SET_VK_SEARCH_AGE_TO:
            return {
                ...state,
                ageTo: action.value
            };

        case ActionType.SET_VK_SEARCH_AGE_OPTIONS_TO:
            return {
                ...state,
                ageOptionsTo: action.options
            };

        case ActionType.SET_VK_SEARCH_ONLY_VERY_SIMILAR_FACES:
            return {
                ...state,
                searchOnlyVerySimilarFaces: action.value
            };

        case ActionType.SET_VK_SCHOOL_IDS_TO_SEARCH:
            return {
                ...state,
                schoolIdsToSearch: action.ids
            };

        case ActionType.SET_VK_SCHOOL_ID_TO_SEARCH:
            return {
                ...state,
                schoolIdToSearch: action.id
            };

        case ActionType.ADD_VK_SEARCH_PROCESSED_USER_ID:
            let processedUserIds = new Set(state.processedUserIds);
            processedUserIds.add(action.id);
            return {
                ...state,
                processedUserIds: processedUserIds
            };

        case ActionType.CLEAR_VK_SEARCH_PROCESSED_USER_IDS:
            return {
                ...state,
                processedUserIds: new Set()
            };

        case ActionType.SET_VK_SEARCH_STATUS:
            return {
                ...state,
                searchStatus: action.status
            };

        case ActionType.SET_VK_SEARCH_PROCESSING_ITEM_INDEX:
            return {
                ...state,
                processingItemIndex: action.index
            };

        case ActionType.SET_VK_SEARCH_DATA_TO_PROCESS:
            return {
                ...state,
                dataToProcess: action.data
            };

        case ActionType.ADD_VK_SEARCH_ITEM:
            return {
                ...state,
                searchItems: [...state.searchItems, action.item]
            };

        case ActionType.SET_VK_SEARCH_ITEM:
            let searchItems = state.searchItems.slice();
            searchItems[action.index] = action.item;
            return {
                ...state,
                searchItems: searchItems
            };

        case ActionType.SET_VK_SEARCH_ITEMS:
            return {
                ...state,
                searchItems: action.items
            };

        case ActionType.SET_VK_SEARCH_TOTAL_ITEMS_COUNT_TO_PROCESS:
            return {
                ...state,
                totalItemsCountToProcess: action.count
            };

        case ActionType.SET_VK_SEARCH_PROCESSED_ITEMS_COUNT:
            return {
                ...state,
                processedItemsCount: action.count
            };

        default: return state
    }
};

export default vkSearch;